// Used by studio/sanity.config.js and web/gatsby-node.js

const supportedLanguages = [
  { id: "en", title: "English", isDefault: true },
  { id: "es", title: "Spanish" },
];

exports.supportedLanguages = supportedLanguages;
exports.defaultLanguage = supportedLanguages.find((l) => l.isDefault);
exports.additionalLanguages = supportedLanguages.filter((l) => !l.isDefault);

exports.default = supportedLanguages;
