import {
  ButtonBase,
  ClickAwayListener,
  IconButton,
  Slide,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { navigate, useLocation, useMatch } from '@reach/router'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { supportedLanguages } from '../../../utils/supportedLanguages'
import cisLogoGrayscale from '../assets/CIS-logomark-grayscale.svg'
import theme from '../styles/theme'
import LocalizedLink from './LocalizedLink'
import CloseIcon from './icons/close'
import MenuIcon from './icons/menu'
import WorldIcon from './icons/world'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    zIndex: 100,
    top: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    transition: 'color .4s ease',
    padding: 25,
    [theme.breakpoints.down('sm')]: {
      padding: '24px 64px',
      color: theme.custom.colors.black,
    },
    [theme.breakpoints.down('xs')]: {
      padding: '24px 20px',
    },
  },
  rootWhite: {
    color: theme.custom.colors.white,
    [theme.breakpoints.down('sm')]: {
      color: theme.custom.colors.black,
    },
  },
  toggleNavButton: {
    width: 24,
    marginRight: 16,
    '& svg': {
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      width: 20,
    },
  },
  leftWrapper: {
    display: 'flex',
    alignItems: 'center',
    zIndex: 100,
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  cisLogoGrayscale: {
    display: 'inline-block',
    margin: '-22px .5em 0',
    position: 'relative',
    top: 14,
  },
  title: {
    margin: 0,
    '& a': {
      display: 'inline-block',
      color: 'inherit',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'none',
      },
    },
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      width: '80%',
      margin: '0 10%',
      textAlign: 'center',
      zIndex: -1,
    },
  },
  counselingTag: {
    margin: 0,
  },
  menu: {
    background: '#FFC433',
    position: 'fixed',
    left: 0,
    top: 0,
    bottom: 0,
    width: '80%',
    zIndex: 90,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      alignItems: 'start',
      paddingTop: 80,
    },
  },
  closeToHomeButton: {
    marginTop: -12,
    fontSize: '1rem',
    border: '.5px solid rgba(0,0,0,0.54)',
    padding: 10,
    transition: 'box-shadow .4s ease',
    '& svg': {
      width: '.8em',
      height: '.8em',
    },
    '&:hover': {
      background: 'none',
      boxShadow: 'inset 0px 0px 7px 0px rgba(0,0,0,.15)',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: -6,
      padding: 6,
      position: 'absolute',
      '& svg': {
        width: 10,
        height: 10,
      },
    },
    [theme.breakpoints.down('xs')]: {
      right: 0,
      marginRight: 12,
    },
  },

  closeButton: {
    display: 'none',
    width: 10,
    position: 'absolute',

    '& svg': {
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      top: 20,
      right: 20,
    },
  },
  menuCounselingTag: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      position: 'absolute',
      width: '100%',
      bottom: 20,
      textAlign: 'center',
    },
  },
  menuItems: {
    listStyle: 'none',
    margin: `0 calc(${100 / 14}% + 16px)`,
    padding: 0,
    '& h1': {
      [theme.breakpoints.down('sm')]: {
        marginBottom: '1em',
        fontSize: 36,
        '& a': {
          lineHeight: 1.2,
        },
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 24,
      },
    },
    [theme.breakpoints.down('sm')]: {
      margin: `0 64px`,
    },
    [theme.breakpoints.down('xs')]: {
      margin: `0 20px`,
    },
    '& li': {
      lineHeight: 2,
    },
    '& li a': {
      lineHeight: 2,
      color: 'inherit',
      textDecoration: 'none',
      '&:hover': {
        color: 'inherit',
        textDecoration: 'none',
        borderBottom: '1px solid currentColor',
      },
    },
  },
  subMenuItems: {
    listStyle: 'none',
    margin: `20px 0 0`,
    padding: 0,
    display: 'flex',
    gap: 12,
  },
}))

export default function Header({ siteSettings, language, allSiteSettings, sections, whiteText }) {
  const classes = useStyles()
  const [showMenu, setShowMenu] = useState(false)
  const [menuActivationFromClick, setMenuActivationFromClick] = useState(false)
  const useWhiteText = whiteText
  const matchHomepage = useMatch('/')
  const matchAboutPage = useMatch('/about')
  const showCloseButton = !matchHomepage && !matchAboutPage
  const mediaSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const location = useLocation()

  console.log('Header siteSettings', siteSettings)
  const handleHideMenu = () => {
    setShowMenu(false)
    setMenuActivationFromClick(false)
  }
  const toggleMenu = () => {
    if (showMenu) {
      handleHideMenu()
    } else {
      setMenuActivationFromClick(true)
      setShowMenu(true)
    }
  }
  const handleClickAway = () => {
    if (menuActivationFromClick) {
      return
    }
    handleHideMenu()
  }

  const handleCloseToHome = () => {
    navigate('/')
  }

  useEffect(() => {
    const timer = setTimeout(() => setMenuActivationFromClick(false), 100)
    return () => clearTimeout(timer)
  }, [menuActivationFromClick])

  return (
    <div className={clsx(classes.root, useWhiteText && !showMenu && classes.rootWhite)}>
      <div className={classes.leftWrapper}>
        <ButtonBase className={classes.toggleNavButton} onClick={() => toggleMenu()}>
          <MenuIcon />
        </ButtonBase>
        <Typography variant="h2" className={clsx(classes.title)}>
          <LocalizedLink currentLanguage={language} to="/">
            {siteSettings?.title}
          </LocalizedLink>
        </Typography>
      </div>
      <div className={classes.rightWrapper}>
        {!showCloseButton && !mediaSmall && (
          <Typography variant="caption" className={classes.counselingTag}>
            {siteSettings?.aResourceSiteBy}
            <img src={cisLogoGrayscale} alt="" className={classes.cisLogoGrayscale} />
            <a href="http://counselinginschools.org" target="_blank" rel="noreferrer">
              Counseling In Schools
            </a>
          </Typography>
        )}
        {showCloseButton && (
          <IconButton className={classes.closeToHomeButton} onClick={handleCloseToHome} edge="end">
            <CloseIcon className={classes.closeToHomeIcon} />
          </IconButton>
        )}
      </div>

      <ClickAwayListener onClickAway={handleClickAway}>
        <Slide direction="right" in={showMenu} timeout={400}>
          <div className={classes.menu}>
            <ButtonBase onClick={handleHideMenu} className={classes.closeButton}>
              <CloseIcon />
            </ButtonBase>
            <ul className={classes.menuItems}>
              <li>
                <Typography variant="h1" color="primary">
                  <LocalizedLink currentLanguage={language} to="/about/">
                    {siteSettings?.aboutThisProject}
                  </LocalizedLink>
                </Typography>
              </li>

              {sections?.map((section) => (
                <li key={section.id}>
                  <Typography variant="h1" color="primary">
                    <LocalizedLink currentLanguage={language} to={`/${section.slug.current}`}>
                      {section.title}
                    </LocalizedLink>
                  </Typography>
                </li>
              ))}

              <li>
                <Typography variant="h1" color="primary">
                  <LocalizedLink currentLanguage={language} to="/activities/">
                    {siteSettings?.activityCorner}
                  </LocalizedLink>
                </Typography>
              </li>

              <li>
                <ul className={clsx(classes.menuItems, classes.subMenuItems)}>
                  <li>
                    <Typography variant="h2" color="primary" style={{ display: 'inline-flex' }}>
                      <WorldIcon style={{ width: '1.5em', height: '1.5em' }} />
                    </Typography>
                  </li>
                  {supportedLanguages.map((languageObject) => {
                    const localizedSite = allSiteSettings?.find(
                      (node) => node.language === languageObject.id
                    )
                    const isCurrentLanguage = language === languageObject.id
                    if (localizedSite) {
                      return (
                        <li key={localizedSite?.id}>
                          <Typography
                            variant="h2"
                            color="primary"
                            style={{
                              fontWeight: isCurrentLanguage ? 'bold' : 'normal',
                            }}
                          >
                            <LocalizedLink
                              to={location.pathname}
                              currentLanguage={language}
                              targetLanguage={languageObject.id}
                            >
                              {languageObject.title}
                            </LocalizedLink>
                          </Typography>
                        </li>
                      )
                    }
                  })}
                </ul>
              </li>
            </ul>

            <Typography variant="caption" className={classes.menuCounselingTag}>
              {siteSettings?.aResourceSiteBy}
              <img src={cisLogoGrayscale} alt="" className={classes.cisLogoGrayscale} />
              <a href="http://counselinginschools.org" target="_blank" rel="noreferrer">
                Counseling In Schools
              </a>
            </Typography>
          </div>
        </Slide>
      </ClickAwayListener>
    </div>
  )
}
