import React from 'react'
export default function() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23.377" height="10" viewBox="0 0 23.377 10">
      <g transform="translate(-67.586 -33.751)">
        <path
          d="M5771.586,3359.751h23.377"
          transform="translate(-5704 -3325)"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
        />
        <path
          d="M5771.586,3359.751h23.377"
          transform="translate(-5704 -3321)"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
        />
        <path
          d="M5771.586,3359.751h23.377"
          transform="translate(-5704 -3317)"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
        />
      </g>
    </svg>
  )
}
