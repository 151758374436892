import React from 'react'
import { Link } from 'gatsby'
import { convertPathLocale } from '../../../utils/localizePath'

export const LocalizedLink = ({ currentLanguage, targetLanguage, to, children, ...props }) => {
  const newRoute = convertPathLocale({
    path: to,
    currentLanguageId: currentLanguage,
    targetLanguageId: targetLanguage
  })
  return (
    <Link {...props} to={newRoute}>
      {children}
    </Link>
  )
}

export default LocalizedLink
