import React from 'react'

const strokeStyle = { vectorEffect: 'non-scaling-stroke' }

// Source icon: https://thenounproject.com/icon/language-5792036/
const WorldIcon = ({ style = {} }) => (
  <svg
    version="1.1"
    viewBox="0 0 64 64"
    width="64"
    height="64"
    style={{ ...style, enableBackground: 'new 0 0 64 64' }}
  >
    <path d="M32,9.5C19.6,9.5,9.5,19.6,9.5,32S19.6,54.5,32,54.5S54.5,44.4,54.5,32S44.4,9.5,32,9.5z M50.2,38.9  c-1.8,0.6-3.7,1.2-5.6,1.6c0.5-2.2,0.8-4.5,0.9-7h5.8C51.2,35.4,50.8,37.2,50.2,38.9z M12.6,33.5h5.8c0.1,2.4,0.4,4.8,0.9,6.9  c-1.9-0.4-3.7-1-5.6-1.6C13.2,37.1,12.8,35.4,12.6,33.5z M13.8,25.2c1.8-0.6,3.7-1.2,5.6-1.6c-0.5,2.2-0.8,4.5-0.9,6.9h-5.8  C12.8,28.6,13.2,26.9,13.8,25.2z M33.5,22.1c2.6,0.1,5.2,0.3,7.8,0.8c0.7,2.3,1.1,4.9,1.2,7.6h-9.1V22.1z M33.5,19.1v-6.3  c2.6,0.7,5,3.2,6.6,6.9C37.9,19.4,35.7,19.2,33.5,19.1z M30.5,12.8v6.3c-2.2,0.1-4.4,0.2-6.6,0.5C25.5,16,27.9,13.5,30.5,12.8z   M30.5,22.1v8.4h-9.1c0.1-2.7,0.6-5.3,1.2-7.6C25.3,22.4,27.9,22.2,30.5,22.1z M21.4,33.5h9.1v8.4c-2.6-0.1-5.2-0.3-7.8-0.8  C22,38.8,21.6,36.2,21.4,33.5z M30.5,44.9v6.3c-2.6-0.7-5-3.2-6.6-6.9C26.1,44.6,28.3,44.8,30.5,44.9z M33.5,51.2v-6.3  c2.2-0.1,4.4-0.2,6.6-0.5C38.5,48,36.1,50.5,33.5,51.2z M33.5,41.9v-8.4h9.1c-0.1,2.7-0.6,5.3-1.2,7.6  C38.7,41.6,36.1,41.8,33.5,41.9z M45.6,30.5c-0.1-2.5-0.4-4.8-0.9-6.9c1.9,0.4,3.7,1,5.6,1.6c0.6,1.7,1,3.5,1.2,5.3H45.6z   M48.3,21.4c-1.5-0.5-3.1-0.8-4.7-1.2c-0.8-2.2-1.8-4.1-3-5.7C43.8,16.2,46.4,18.5,48.3,21.4z M23.3,14.6c-1.2,1.6-2.2,3.5-3,5.7  c-1.6,0.3-3.1,0.7-4.7,1.2C17.6,18.5,20.2,16.2,23.3,14.6z M15.7,42.6c1.5,0.4,3.1,0.8,4.7,1.2c0.8,2.2,1.8,4.1,3,5.7  C20.2,47.8,17.6,45.5,15.7,42.6z M40.7,49.4c1.2-1.6,2.2-3.5,3-5.7c1.6-0.3,3.1-0.7,4.6-1.1C46.4,45.5,43.8,47.8,40.7,49.4z" />
  </svg>
)

export default WorldIcon
