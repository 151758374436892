import React, { useEffect } from 'react'
import Header from './header'
import { makeStyles } from '@material-ui/core/styles'
import '../styles/layout.css'
import theme from '../styles/theme'
import { ThemeProvider } from '@material-ui/core/styles'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  root: {
    boxSizing: 'border-box',
    height: '100vh',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      height: `auto`,
      minHeight: '100%',
      overflow: 'auto'
    }
  },
  rootInternalScroll: {
    // position: 'fixed',
    // top: 0,
    // bottom: 0,
    // left: 0,
    // right: 0,
    overflowX: 'hidden',
    overflowY: 'scroll',
    [theme.breakpoints.down('sm')]: {
      height: '100vh',
      overflowX: 'hidden',
      overflowY: 'scroll'
    }
  }
}))

const Layout = React.forwardRef(
  (
    {
      children,
      siteSettings,
      allSiteSettings,
      sections,
      className,
      language,
      whiteText = false,
      backgroundColor = 'transparent',
      internalScroll = false
    },
    ref
  ) => {
    const classes = useStyles()

    useEffect(() => {
      document.documentElement.style.setProperty(
        '--vh',
        `${document.documentElement.clientHeight / 100}px`
      )
    }, [])

    return (
      <ThemeProvider theme={theme}>
        <div
          className={clsx(
            'layoutRoot',
            classes.root,
            internalScroll && classes.rootInternalScroll,
            className
          )}
          ref={ref}
          style={{ backgroundColor: backgroundColor }}
        >
          <Header
            language={language}
            siteSettings={siteSettings}
            allSiteSettings={allSiteSettings}
            sections={sections}
            whiteText={whiteText}
          />
          <div>{children}</div>
        </div>
      </ThemeProvider>
    )
  }
)

export default Layout
