import React from 'react'
export default function() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.54"
      height="18.539"
      viewBox="0 0 18.54 18.539"
    >
      <g transform="translate(-1036.088 -29.646)">
        <path
          d="M6761.274,3356l-17.832,17.832"
          transform="translate(-5707 -3326)"
          fill="none"
          stroke="currentColor"
          strokeWidth="1"
        />
        <path
          d="M6761.274,3356l-17.832,17.832"
          transform="translate(4410.273 -6713.442) rotate(90)"
          fill="none"
          stroke="currentColor"
          strokeWidth="1"
        />
      </g>
    </svg>
  )
}
