const {
  defaultLanguage,
  additionalLanguages,
  supportedLanguages,
} = require("./supportedLanguages");

const removeTrailingSlash = (path) =>
  path === `/` ? path : path.replace(/\/$/, ``);

exports.localizePath = ({ languageId, path }) => {
  return languageId === defaultLanguage.id
    ? `${path}`
    : `/${languageId}${path}`;
};

exports.convertPathLocale = ({
  currentLanguageId = defaultLanguage.id,
  targetLanguageId,
  path,
}) => {
  const pathParts = path.split("/");
  const currentLanguageIndex = pathParts.findIndex(
    (part) => part === currentLanguageId
  );
  if (currentLanguageIndex > -1) pathParts.splice(currentLanguageIndex, 1);
  return exports.localizePath({
    languageId: targetLanguageId || currentLanguageId,
    path: pathParts.join("/"),
  });
};
