import { graphql, StaticQuery } from 'gatsby'
import React from 'react'
import Layout from '../components/layout'

const query = graphql`
  query LayoutQuery {
    allSiteSettings: allSanitySiteSettings {
      edges {
        node {
          id
          title
          language
          description
          aboutThisProject
          activityCorner
          aResourceSiteBy
        }
      }
    }
  }
`

const LayoutContainer = React.forwardRef((props, ref) => {
  const { language, sections, siteSettings } = props
  // console.log('LayoutContainer props', props)
  return (
    <StaticQuery
      query={query}
      render={(data) => {
        const flattenedAllSiteSettings = data.allSiteSettings?.edges?.map((edge) => edge.node)
        const flattenedSections = sections?.edges?.map((edge) => edge.node)

        return (
          <Layout
            {...props}
            language={language}
            siteSettings={siteSettings}
            allSiteSettings={flattenedAllSiteSettings}
            sections={flattenedSections}
            ref={ref}
          />
        )
      }}
    />
  )
})

export default LayoutContainer
